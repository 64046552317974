import Swiper, { Navigation, Mousewheel } from 'swiper';

import 'swiper/swiper-bundle.min.css';

export const timeline = () => {
  let spv = 3;

  if (window.innerWidth < 768) {
    spv = 1.3;
  }
  if (window.innerWidth > 768) {
    spv = 2.3;
  }
  if (window.innerWidth > 1200) {
    spv = 3;
  }

  let soff = 100;

  if (window.innerWidth < 768) {
    soff = 0;
  }
  if (window.innerWidth > 768) {
    soff = 100;
  }

  var timeline = new Swiper('.timeline-slider', {
    slidesPerView: spv,
    spaceBetween: 10,
    mousewheel: true,
    slidesOffsetBefore: soff,

    navigation: {
      nextEl: '.timeline-block__button--next',
      prevEl: '.timeline-block__button--prev',
    },
    modules: [Navigation, Mousewheel],
  });
};
