export class teamPopup {
  constructor() {
    this.teamMembersPopups = [...document.querySelectorAll('.team-block')];
    this.popups = [...document.querySelectorAll('[data-popup]')];

    if (this.teamMembersPopups.length > 0) {
      this.buttons = document.querySelectorAll('.team-block__member');
      this.init();
    }
  }

  init() {
    const closeButtons = [...document.querySelectorAll('.team-block__popup--close')];

    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener('click', (e) => {
        this.popups.forEach((popup) => {
          if (!popup.classList.contains('hidden')) {
            popup.classList.add('hidden');
            document.body.classList.toggle('overlay');
          }
        });
      });
    });

    this.buttons.forEach((button) =>
      button.addEventListener('click', (e) => {
        e.stopPropagation();
        const target = e.target.getAttribute('data-popup-target');

        const popup = this.popups.find((popup) => {
          return popup.getAttribute('data-popup') === target;
        });

        popup.classList.toggle('hidden');
        document.body.classList.toggle('overlay');
      })
    );
  }
}
