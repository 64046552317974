import Swiper, { Navigation, Mousewheel } from 'swiper';

import 'swiper/swiper-bundle.min.css';

export const companySlider = () => {
  let spv = 4.2;

  if (window.innerWidth < 768) {
    spv = 1.2;
  }
  if (window.innerWidth > 768) {
    spv = 2.2;
  }
  if (window.innerWidth > 1200) {
    spv = 4.2;
  }

  var companySlider = new Swiper('.company-slider', {
    slidesPerView: spv,
    spaceBetween: 10,
    mousewheel: true,

    navigation: {
      nextEl: '.companies-slider__button--next',
      prevEl: '.companies-slider__button--prev',
    },
    modules: [Navigation, Mousewheel],
  });
};
