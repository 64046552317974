'use strict';

import { timeline } from './includes/timeline';
import { companySlider } from './includes/companySlider';
import { imageSlider } from './includes/imageSlider';
import { teamPopup } from './includes/teamPopup';

document.addEventListener('DOMContentLoaded', () => {
  mobileMenu();
  timeline();
  companySlider();
  imageSlider();
  new teamPopup();
  const heroButton = document.querySelector('button.hero__arrow-down');
  if (heroButton) {
    heroButton.addEventListener('click', () => scrollDown());
  }
});

require('fslightbox');

const mobileMenu = () => {
  const mobileToggle = document.querySelector('button.header__toggle');
  const mobileMenu = document.querySelector('.mobile-menu');
  const logo = document.querySelector('a.header__logo svg');
  mobileToggle.addEventListener('click', () => {
    document.body.classList.toggle('fixed');
    mobileMenu.classList.toggle('is-open');
    logo.classList.toggle('fill-green');
    mobileToggle.classList.toggle('fill-green');
  });
};

const scrollDown = () => {
  const hero = document.querySelector('.hero');
  const heroHeight = hero.offsetHeight;
  window.scrollBy(0, heroHeight);
};
