import Swiper, { Navigation, Mousewheel } from 'swiper';

import 'swiper/swiper-bundle.min.css';

export const imageSlider = () => {
  var imageSlider = new Swiper('.image-slider', {
    slidesPerView: 'auto',
    spaceBetween: 10,
    mousewheel: true,

    navigation: {
      nextEl: '.image-slider-block__button--next',
      prevEl: '.image-slider-block__button--prev',
    },
    modules: [Navigation, Mousewheel],
  });
};
